@media only screen and (max-width: 767px) {
    .HeadingMessageManager {
        max-height: 100%;

        /*min-width: 300px;*/
        /*max-width: 400px;*/
        overflow-y: hidden;
        overflow-x: hidden;

    }
}

@media only screen and (min-width: 768px) {
    .HeadingMessageManager {
       
        /*min-width: 300px;*/
        /*max-width: 400px;*/
        overflow-y: scroll;
        overflow-x: hidden;
        /*padding: 8px 5px;*/
    }
}

.AllClear {
    font-size: 14px;
    margin-top: 15px;
    width: 100%;
    text-align: center;
}

.MessageBtn {
    width: 100%;
    height: 40px;
    border: solid 2px #4a77f7;
    color: #4a77f7;
    border-radius: 30em;
    padding: 5px;
    background-color: white;
}

.MessageBtn:hover {
    background-color: #4a77f7;
    color: white;
}

.Img {
    max-width: 300px;
    display: block;
    margin: 2px auto;
    width: 100%;
}

.HeadingMessageManager {
    min-height: 300px;
    /*overflow-y: hidden;*/
}
