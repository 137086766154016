@media only screen and (min-width: 768px) {
    .messageText {
        text-align: left;
        /*background-color: #e8e8e8;*/
        border-radius: 0.75em;
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    .messageTextWithMaxWidth {
        margin-right: 5px;
        margin-left: 10px;
        /*max-width: 310px;*/
    }
}

@media only screen and (max-width: 767px) {
    .messageText {
        text-align: left;
        /*background-color: #e8e8e8;*/
        border-radius: 0.75em;
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    .messageTextWithMaxWidth {
        margin-right: 10px;
        margin-left: 10px;
    }
}

.root {
    margin: 0;
    height: calc(100vh - 170px);
    display: inline-grid;
    grid-template-rows: auto 60px;
}

.ExpandRoot {
    margin: 0;
    height: calc(100vh - 170px);
    display: inline-grid;
    grid-template-rows: auto 400px;
}

.chatInput {
    overflow-y: scroll;
    height: 100%;
    padding: 0 15px 0 15px;
}

.chatRoomMessage {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
    text-align: left;
}

.MessageContainer {
    max-width: 400px;
    display: inline-grid;
    grid-template-columns: 40px auto;
    left: 0;
}

.MessageContainerRight {
    max-width: 400px;
    display: inline-grid;
    grid-template-columns: auto 40px;
    left: 0;
}

.chatRoomMessageRight {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
    text-align: right;
}

.divider {
    margin-bottom: 8px;
}

.Time {
    position: relative;
    right: 0;
    border: 0;
    font-size: 12px;
    color: #a19f9f;
    text-align: right;
}

.ImgContainer > img {
    max-width: 300px !important;
    width: 100%;
    max-height: 385px;
    object-fit: cover;
}

.ImgContainer {
    min-width: 300px;
    max-width: 400px;
    height: 200px;
    object-fit: cover;
}

.ImgContainer > figure {
    width: 100% !important;
    border-bottom: solid 2px #e8e8e8;
    margin: 0;
}

.ImgContainer > figure > div {
    height: 150px;
    border-radius: 7px 7px 0 0;
    object-fit: cover;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWBAMAAADOL2zRAAAAKlBMVEXl5ef29vbt7u/+/v7c3d/p6evx8vL////29vfr6+34+Pnn5+n9/f36+/uRu7ACAAABT0lEQVRo3u2XsU0DQRBFxzaHhEgYg4nNAAFyglkXgM0UYKjAVLEIQcwKGQnRBISIJqADKIfgZm8iAsQGRvcdvuCfzvr3R4/SMl6nh3iTUowpvcev9Byv0p3Be4cfBm8zfEmfBpc1JJ2GY52FsWoIqtvhQHthomcGTx3uGDzJcE33DU5rWDSLB7TOfaqYiZjntMcL6vCWwV2H5wa7GV7y0OCghuSxqqF51sTg2GHP4CzD/A7Ni61slpT7rWwnSmaV/L/a0K+S32Mb+lUyqw39KpnlVWOmpn8dg5XDhcF+hrnXXnZsIbYQt/ZvWSW/R2zh77Jwa+Ed2EJsIbwD3gHvgHfAO/7xrR3RhhzSpgiRyCtdyCN1ZWjwyOGbwSrDJ5kbHNUQtxa3Ft4B74B3wDuwhfAOeAe8A94B78AW4tbCO+Ad8A54B7YQWwjvQL9+yvoG+3hNMUs0RhQAAAAASUVORK5CYII=");
    min-width: 100%;
}

.ImgContainer > figure > div > img {
    height: 150px;
    object-fit: cover;
}

.ImageDetail {
    margin: 5px 0;
    color: #757575;
    font-size: 12px;
}

.LinkContainer > svg {
    margin: 0 4px;
}

.LinkContainer {
    padding: 10px 20px;
    /*background-color: #2196f3;*/
    border-radius: 0.75em;
    /*color: white;*/
    margin: 5px 0;
}

@media only screen and (min-width: 768px) {
    .LinkContainer {
        padding: 8px 8px;
    }

}
