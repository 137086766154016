@media only screen and (min-width: 768px) {
  .SideBar {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .SideBar {
    display: flex !important;
  }
}

.SideBar {
  height: 100% !important;
  display: none;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  transition: 0.6s ease;
}
