HeadingIconNavItem {
  display: inline-block;
  list-style-type: none;
  width: 18px !important;
  height: 18px;
  position: absolute;
}

.HeadingIconNavItem > li > a {
  padding: 0;
}

.HeadingIconNavItem > li > div {
  box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border: solid #e8e8e8 1px;
  border-radius: 0.75em;
}

.NotiRequest {
  animation: bellshake 2s infinite;
  display: inline-block;
  list-style-type: none;
  width: 14px !important;
  height: 16px;
  /*position: absolute;*/
  color: rgb(225, 125, 114);
}

.NotiRequest > li > a {
  padding: 0;
}

.NotiRequest > li > div {
  box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border: solid #e8e8e8 1px;
  border-radius: 0.75em;
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  45% {
    transform: rotate(8deg);
  }
  60% {
    transform: rotate(-8deg);
  }
  75% {
    transform: rotate(4deg);
  }
  85% {
    transform: rotate(-4deg);
  }
  92% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}
