.shadowElement {
    box-shadow: 3px 10px 10px 0px #00000010;
    transition: box-shadow .3s;

}

.customTable {
    border-collapse: initial;
    border-spacing: 0px;
}

.tableScrollBar {
    display: block;
}

.tableScrollBar::-webkit-scrollbar {
    /* Reset global scrollbar styles */
    display: initial;
}

.recommendScrollBar::-webkit-scrollbar {
    /* Reset global scrollbar styles */
    display: initial;
}

.recommendScrollBar::-webkit-scrollbar {
    /* Reset global scrollbar styles */
    display: initial;
}